<script>
    import axios from 'axios';

    import qs from 'qs';

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'

    import { quillEditor } from 'vue-quill-editor'

    import VueDraggableResizable from 'vue-draggable-resizable'
    import 'vue-draggable-resizable/dist/VueDraggableResizable.css'

    // import VueMarkdown from 'vue-markdown'

    // import wysiwyg from "vue-wysiwyg";

    import _ from 'lodash';

    export default {
        name: 'AssetBuilder',

        components: {
            VueDraggableResizable,
            quillEditor,
            // VueMarkdown

        },



        created() {
            axios.get('/print-materials/' + window.vueEntryId + '.json')
                .then(response => {
                  console.log({response})
                    // JSON responses are automatically parsed.
                    let asset = response.data;
                  asset.ready = true;
                    let aspectRatio = asset.size.width / asset.size.height;

                    this.previewWidth = 360;
                    if (aspectRatio < 1) {
                        this.previewWidth = 360;
                    }

                    if (aspectRatio < 0.5) {
                        this.previewWidth = 100;
                    }

                    asset.preview = {
                        width: this.previewWidth,
                        height: asset.size.height / asset.size.width * this.previewWidth,
                        scale: this.previewWidth / (asset.size.width * 72)
                    };

                    asset.gridBounds = {
                        width: Math.round(parseFloat(asset.size.width)),
                        height: Math.round(parseFloat(asset.size.height)),
                    };

                    asset.size_adjusted = _.clone(asset.size);
                    console.log('asset.size_adjusted', asset.size_adjusted);

                    // Now that we know the size of the asset, set the dimensions for the preview container


                    // If there's a base PDF specified, set the background image

                    // If there's a base PDF specified, set the background image
                    if (asset.originalArtFiles) {
                        console.log("WE HAVE IT");
                        console.log(asset.originalArtFiles);
                        this.asset.originalArtFiles = asset.originalArtFiles;
                    }


                    asset.layers = this.parseLayers(asset);
                    this.asset = asset;
                })
                .catch(e => {
                  console.log({e})
                    console.log(e);
                    this.status.errors.push(e.response.data)
                })
            for (let index = 0; index < teams.length; index++) {
                axios.get('/teams/' + window.teams[index] + '.json')
                    .then(response => {

                        let team = response.data;

                        // Set selected to false for each logo
                        _.forEach(team.logos, function (logo) {
                            logo.selected = false;
                        });

                        this.teams.push(team);
                        console.log("teams:");
                        console.log(team);


                    })
                    .catch(e => {
                        console.log(e);
                        this.status.errors.push(e.response.data)
                    })

            }
        },
        data: function () {
            return {
                zoom: 100,
                previewWidth: 200,
                generatorData: {},
                debug: false,
                userId: window.userId,
                assetPreview: '',
                savedAsset: null,
                myHTML: "",
                showGridLines: false,
                showResize: false,
                teams: [],
                pdfUrl: null,
                lastPdf: null,
                sizeMultiplier: 1,
                email: {
                    email: '',
                    message: ''
                },
                generating: false,
                editorOption: {
                         modules: {
                           toolbar: [
                             ['bold', 'italic', 'underline'],
                             // [{ 'header': 1 }, { 'header': 2 }],
                             [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                             // [{ 'header': [1, 2, 3, false] }],
                             ['clean'],
                           ],
                           syntax: {
                             highlight: text => hljs.highlightAuto(text).value
                           }
                         }
                       },
                asset: {
                    ready: false,
                    layers: [],
                    parsedLayers: [],
                    size: {
                        width: 0,
                        height: 0
                    },
                    size_adjusted: {
                        width: 0,
                        height: 0,
                        widthPrevious: 0,
                        heightPrevious: 0
                    },
                    size_scale: 1,
                    preview: {
                        width: 0,
                        height: 0
                    }
                },
                status: {
                    errors: [],
                },
                errors: [],
                position: {
                    x: 18,
                    y: 306,
                    width: 101,
                    height: 100
                }
            };
        },
        computed: {
            reverseLayers() {
                return this.asset.layers.slice().reverse();
            },

            previewStyle() {
                let style = {
                    width: this.asset.preview.width + 'px',
                    height: this.asset.preview.height + 'px',
                    transform: 'scale(' + this.zoom/100 + ')',
                    transformOrigin: 'top'
                };
                if (this.asset.basePdf) {
                    style.backgroundImage = 'url(' + this.asset.basePdf.preview + ')';
                }

                return style;
            },
            orientationClass() {
                let aspectRatio = this.asset.size.width / this.asset.size.height;
                let orientation = 'portrait';
                if (aspectRatio < 0.5) {
                    orientation += " portrait-tall"
                }
                if (aspectRatio > 1) {
                    orientation = 'landscape';
                }
                if (aspectRatio > 2) {
                    orientation += " landscape-wide"
                }

                return orientation;
            }
        },
        methods: {
            zooom: function(amount) {
              console.log('zoom in');
              if(this.zoom < 200) {
                  this.zoom += amount;
              }
            },
            resetSize: function() {
                console.log('resetSize');
                this.asset.size_adjusted = _.clone(this.asset.size);
                return false;
            },
            syncHeight: function() {
                console.log('adjusting height');
                // If the value didn't change, don't recalculate
                if (this.asset.size_adjusted.width === this.asset.size_adjusted.widthPrevious) {
                    console.log('no change');
                    return;
                }
                this.asset.size_adjusted.message = null;
                if (isNaN(this.asset.size_adjusted.width)) {
                    alert('Please enter a valid number');
                    this.asset.size_adjusted.width = this.asset.size.width;
                }
                let scale = this.asset.size_adjusted.width / this.asset.size.width;
                if (scale > 1.3) {
                    scale = 1.3;
                    let max = this.asset.size.width * scale;
                    this.asset.size_adjusted.message = "Size can only be adjusted by 30%. Setting to max width of " + max
                    this.asset.size_adjusted.width = max;
                }
                this.asset.size_adjusted.height = this.asset.size.height * scale;
                this.asset.size_adjusted.heightPrevious = this.asset.size.height * scale;
                this.asset.size_scale = scale;
            },
            syncWidth: function() {
                console.log('adjusting width');

                // If the value didn't change, don't recalculate
                if (this.asset.size_adjusted.height === this.asset.size_adjusted.heightPrevious) {
                    console.log('no change');
                    return;
                }
                this.asset.size_adjusted.message = null;
                if (isNaN(this.asset.size_adjusted.height)) {
                    alert('Please enter a valid number');
                    this.asset.size_adjusted.height = this.asset.size.height;
                }
                let scale = this.asset.size_adjusted.height / this.asset.size.height;
                if (scale > 1.3) {
                    scale = 1.3;
                    let max = this.asset.size.height * scale;
                    this.asset.size_adjusted.message = "Size can only be adjusted by 30%. Setting to max height of " + max
                    this.asset.size_adjusted.height = max;
                }
                this.asset.size_adjusted.width = (this.asset.size.width * scale);
                this.asset.size_scale = scale;
            },
          trackEvent: function(eventAction) {
            // let label = this.asset.title + ' - ' + this.asset.size.width + 'x' + this.asset.size.height;
            let eventLabel = this.asset.title;

            let eventCategory = "General Print Asset";
            if (this.asset.assetCategory && this.asset.assetCategory.title) {
              eventCategory = this.asset.assetCategory.title;
            }

            let matomoEvent = ['trackEvent', eventCategory, eventAction, eventLabel, {dimension3: eventCategory}];
            console.log('matomoEvent', matomoEvent);

            try {
              _paq.push(matomoEvent);
            } catch (e) {
              console.log('Error tracking Matomo event:');
              console.log(e)
            }

            try {
              gtag('event', eventAction, {
                'event_category': eventCategory,
                'event_label': eventLabel,
              });
            } catch (e) {
              console.log('Error tracking Gtag event:');
              console.log(e)
            }
          },
            clearErrors: function() {
                this.status.errors = [];
            },
            generatePdf: function () {
                this.trackEvent('generate');
                this.generating = 'Generating PDF';
                this.pdfUrl = null;
                this.status.errors = [];
                let data = {
                    title: this.asset.title,
                    size: this.asset.size,
                    size_scale: this.asset.size_scale,
                    last: this.lastPdf,
                    layers: this.prepLayers()
                };
                if (this.asset.basePdf) {
                    data.basePdf = this.asset.basePdf;
                }

                this.generatorData = {layers: this.prepLayers()};
                console.log('generatorData', this.generatorData);
                console.log('data', data);
                this.generatorData = {data: data};

                data[window.csrfTokenName] = window.csrfTokenValue;
                axios.post('/actions/pdf-generator-module/default', qs.stringify(data), {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(response => {
                        let filename = response.data.filename;
                        let hash = response.data.hash;
                        this.pdfUrl = response.data.s3url;
                        this.generating = false;
                        this.lastPdf = {
                            filename: filename,
                            hash: hash
                        };
                    })
                    .catch(e => {
                        console.log(e);
                        this.generating = false;
                        this.status.errors = [e.response.data];

                    });

            },
            emailAsset: function() {
                console.log('emailAsset()');
                this.saveAsset()
                    .then(response => {
                        console.log('RESPONSE COMPLETE');
                        this.generating = 'Sending Email';
                        let data = {
                            title: this.asset.title,
                            filename: this.lastPdf.filename,
                            hash: this.lastPdf.hash,
                            userId: this.userId,
                            asset: this.savedAsset,
                            email: this.email
                        };
                        data[window.csrfTokenName] = window.csrfTokenValue;
                        axios.post('/actions/pdf-generator-module/default/email', qs.stringify(data), {
                            headers: {
                                'X-Requested-With': 'XMLHttpRequest'
                            }
                        })
                            .then(response => {
                                this.generating = false;
                            })
                            .catch(e => {
                                console.log(e);
                                this.generating = false;
                                this.status.errors = [e.response.data];

                            });
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            saveAsset: function(sendEmail) {
                this.generating = 'Saving File';
                let data = {
                    title: this.asset.title,
                    filename: this.lastPdf.filename,
                    hash: this.lastPdf.hash,
                    userId: this.userId,
                };
                data[window.csrfTokenName] = window.csrfTokenValue;
                return axios.post('/actions/pdf-generator-module/default/upload', qs.stringify(data), {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                    .then(response => {
                        this.generating = false;

                        this.savedAsset = response.data;

                        this.assetPreview = "https://platform-b.imgix.net/" + response.data.path + '?w=250&h=250';
                        // if (sendEmail) {
                        //     alert("Send It!");
                        // }
                    })
                    .catch(e => {
                        console.log(e);
                        this.generating = false;
                        this.status.errors = [e.response.data];

                    });
            },
            prepLayers: function () {
                console.log("PREP LAYERS");
                var preppedLayers = [];
                let scale = this.asset.preview.scale;
                _.forEach(this.asset.layers, function (layer) {
                    console.log(layer.label);

                    console.log(layer);

                    var preppedLayer = {
                        type: layer.type.handle,
                        top: layer.top,
                        left: layer.left,
                        right: layer.right,
                        bottom: layer.bottom,
                        align: layer.align,
                        fontSize: layer.fontSize,
                        color: layer.color,
                        lineHeight: layer.lineHeight
                    };



                    // if (layer.type == 'userText') {
                    //     preppedLayer.fontSize = layer.fontSize;
                    //     preppedLayer.lineHeight = layer.lineHeight;
                    // }
                    if (layer.selectedOption) {
                        preppedLayer.imageURL = layer.selectedOption.image.url;
                        preppedLayers.push(preppedLayer);

                        // Check for sub-layers (body copy)
                        if (layer.selectedOption.selectedOption) {
                            console.log('we have to go deeper');
                            var preppedSubLayer = {
                                type: 'bodyCopy',
                                top: layer.bodyCopyTop,
                                left: layer.bodyCopyLeft,
                                right: layer.bodyCopyRight,
                                imageURL: layer.selectedOption.selectedOption.image.url
                            };
                            preppedLayers.push(preppedSubLayer);
                            console.log('preppedSubLayer', preppedSubLayer);
                        }
                    }

                    if (layer.draggable) {
                        preppedLayer.draggable = _.mapValues(layer.draggable, function (val) {
                            return val / scale / 72;
                        })
                    }

                    if (layer.userText) {
                        console.log("HAVE USERTEXT");
                        preppedLayer.userText = layer.userText;
                        preppedLayers.push(preppedLayer);

                    }

                    if (layer.selectedLogo) {
                        console.log('layer.selectedLogo', layer.selectedLogo);
                        preppedLayer.imageURL = layer.selectedLogo.url;
                        // preppedLayer.top = parseInt(layer.top, 10) + (parseInt(layer.draggable.top, 10) / 72);
                        // preppedLayer.left = parseInt(layer.left, 10) + (parseInt(layer.draggable.left, 10) / 72);
                        // if (layer.draggable) {
                        //     preppedLayer.draggable = _.mapValues(layer.draggable, function (val) {
                        //         return val / scale / 72;
                        //     })
                        // }
                        preppedLayers.push(preppedLayer);
                    }
                });
                return preppedLayers;
            },
            parseLayers: function (asset) {
                console.log("parsing Layers");
                let layers = asset.layers;
                // Set selected to false for all options
                let scale = asset.preview.scale;
                let zIndex = layers.length;
                _.forEach(layers, function (layer) {
                    console.log('layer', layer);
                    layer.active = false;
                    layer.zIndex = zIndex;
                    // if (layer.type.handle == 'userText') {
                    //     layer.zIndex = zIndex * 10;
                    // }

                    layer.zIndexDefault = zIndex;
                    if (layer.type.handle == 'logo') {
                        console.log("ITS A LOGO");
                        layer.zIndex = zIndex * 20;
                        layer.zIndexDefault = zIndex * 20;

                    }
                    zIndex--;
                    layer.previewWidth = (parseFloat(asset.size.width) - (parseFloat(layer.left) + parseFloat(layer.right))) * 72 * 1.5;
                    if (layer.bodyCopyRight && layer.bodyCopyLeft) {
                        layer.bodyCopyPreviewWidth = (parseFloat(asset.size.width) - (parseFloat(layer.bodyCopyLeft) + parseFloat(layer.bodyCopyRight))) * 72 * 1.5;
                    }
                    _.forEach(layer.options, function (option) {
                        option.selected = false;
                        _.forEach(option.bodyCopyOptions, function (option) {
                            option.selected = false;
                        });
                    });
                    if (layer.options.length === 1) {
                        layer.selectedOption = layer.options[0];
                    }

                    if (layer.type.handle == 'userText') {
                        console.log("user text layer:");
                        console.log(layer);
                        if (layer.defaultContent) {
                            layer.userText = layer.defaultContent;
                        } else {
                            layer.userText = "";
                        }
                        layer.draggable = {
                            left: 0,
                            top: 0,
                            height: layer.height * 72 * scale,
                            width: (asset.size.width - layer.left - layer.right) * 72 * scale,

                        };
                        console.log('layer.right', layer.right);
                        console.log('layer.left', layer.left);
                    }
                });
                layers = layers.reverse();
                return layers;
            },

            selectOption: function (option, layer) {
                // Mark the option as selected for the customizer interface
                _.forEach(layer.options, function (option) {
                    option.selected = false;
                });
                // Reverse the array so that the layers stack properly
                option.selected = true;
                // Pull the selected option out of the options array
                layer.selectedOption = option;
                // this.generatePdf();

            },
            activateLayer: function (layer) {
                console.log("activate layer");
                console.log(layer.label);
                layer.active = true;
                console.log(layer);
            },
            selectLogo: function (logo, layer) {
                console.log("---------------");
                console.log('selectLogo');
                console.log('logo', logo);
                console.log('layer', layer);
                layer.selectedLogo = null;
                // Mark the option as selected for the customizer interface
                _.forEach(this.teams, function (team) {
                    console.log(team.title);
                    _.forEach(team.logos, function (logo) {
                        logo.selected = false;
                    });
                });
                logo.selected = true;
                layer.active = true;
                logo.pixelHeight = layer.height * 72 * this.asset.preview.scale;
                // Set the width to maintain aspect ratio
                logo.pixelWidth = logo.width / logo.height * logo.pixelHeight;

                let layerWidth = (this.asset.size.width - layer.left - layer.right) * 72 * this.asset.preview.scale;

                console.log('logo before resize', logo);
                if (logo.pixelWidth > layerWidth) {
                    console.log('logo is wider than container. resizing.');
                    logo.pixelWidth = layerWidth;
                    logo.pixelHeight = logo.height / logo.width * logo.pixelWidth;
                    console.log('logo', logo);
                }
                console.log('layerWidth', layerWidth);
                let left = (layerWidth - logo.pixelWidth) / 2;
                console.log('this.asset.preview', this.asset.preview);
                layer.draggable = {
                    top: 0,
                    left: left,
                    width: logo.pixelWidth,
                    height: logo.pixelHeight
                };
                layer.selectedLogo = logo;
                // // Reverse the array so that the layers stack properly
                // option.selected = true;
                // // Pull the selected option out of the options array
                // layer.selectedOption = option;
            },
            selectNestedOption: function (nestedOption, option) {
                console.log('nestedOption', nestedOption);
                console.log('option', option);
                // Mark the option as selected for the customizer interface
                _.forEach(option.bodyCopyOptions, function (nestedOption) {
                    nestedOption.selected = false;
                });
                // Reverse the array so that the layers stack properly
                nestedOption.selected = true;
                // Pull the selected option out of the options array
                option.selectedOption = nestedOption;
                // this.generatePdf();

            },
            onActivate: function (layer) {
                console.log('drag ACTIVATED');
                layer.zIndex = 1000;
                console.log(layer);
                layer.active = true;
            },
            onDeactivate: function (layer) {
                console.log('drag DEACTIVATED');
                console.log(layer.label);
                layer.zIndex = layer.zIndexDefault;
                layer.active = false;
                console.log(layer);
            },
            onResize: function (layer, left, top, width, height) {
                console.log('resized');
                console.log('layer', layer);
                console.log('layer.selectedLogo', layer.selectedLogo);
                console.log('left', left);
                console.log('top', top);
                console.log('width', width);
                console.log('height', height);
                // this.x = x
                // this.y = y
                let logo = layer.selectedLogo;
                layer.draggable.width = width;
                layer.draggable.height = height;
            },
            onDrag: function (layer, left, top) {
                console.log('dragged');
                console.log('layer', layer);
                console.log('left', left);
                console.log('top', top);
                layer.draggable.left = left;
                layer.draggable.top = top;

                // this.x = x
                // this.y = y
            }
        }
    };
</script>

<template>
    <div class="wizard-container">
        <div class="wizard-toolbar wizard-toolbar-bottom" v-if="reverseLayers.length">
            <nav class="navbar navbar-expand-lg navbar-light ">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <form class="form-inline">
                            <div class="btn-toolbar wizard-actions" >
                                <button type="button" class="btn btn-success"
                                        v-bind:disabled="generating"
                                        v-on:click="generatePdf()">
                                    <span v-if="!generating">Generate PDF</span>
                                    <span v-if="generating">{{generating}}...</span>
                                </button>
                                <div v-if="pdfUrl">
                                  <a v-if="pdfUrl" v-bind:href="pdfUrl"
                                     target="_blank" rel="noopener"
                                     v-on:click="trackEvent('download')"
                                     class="btn btn-secondary"><i class="fas fa-eye"></i> View / Download</a>
                                    <button v-if="lastPdf" type="button" class="btn btn-secondary" data-toggle="modal"
                                            data-target="#exampleModal">
                                        <i class="fas fa-share"></i> Share
                                    </button>
                                </div>
                            </div>
                        </form>
                        <li class="nav-item dropdown" v-if="asset.originalArtFiles">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Original Art Files
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a v-for="file in asset.originalArtFiles" v-bind:href="'/download/' + file.downloadToken" class="dropdown-item" target="_blank">
                                    <i class="fas fa-download"></i> {{file.title}}
                                </a>
                            </div>
                        </li>

                    </ul>
                </div>



            </nav>

            <div class="wizard-preview-zoom">
                <i class="fas fa-search-minus"></i>
                <input type="range" class="custom-range" min="20" max="180" id="zoom" v-model="zoom">
                <i class="fas fa-search-plus"></i>
            </div>
        </div>
        <div class="stickyStatus container">
            <div class="alert" v-if="status.loading">
                {{status.loading}}
            </div>
            <div class="alert alert-success" v-if="status.success">
                {{status.success}}
            </div>
            <div class="alert alert-danger" v-if="status.errors.length">
                <button type="button" class="close" v-on:click="clearErrors()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <code>{{status.errors}}</code>

            </div>
        </div>

        <div class="wizard container-fluid">
            <div class="wizard-ui" id="wizard-ui">


                <div class="card" v-if="asset.ready">
                    <!--<div class="card-header bg-dark">Customize</div>-->
                    <div class="card-body">
                        <div v-for="layer in reverseLayers" :key="layer.id">
                            <div v-if="layer.options.length > 1">
                                <div class="wizard-section wizard-options" v-bind:class="'wizard-options-' + layer.type.handle">
                                    <div class="wizard-section-header">
                                        <h2>{{layer.label}}</h2>
                                    </div>
                                    <div class="wizard-section-body">
                                        <div class="wizard-option" v-for="option in layer.options" :key="option.id">
                                            <!--<div class="wizard-section-divider" v-if="option.label">-->
                                                <!--{{option.label}}-->
                                            <!--</div>-->
                                            <button class="btn-image" v-on:click.prevent="selectOption(option, layer)"
                                                    v-bind:class="{selected: option.selected}">
                                                <img v-bind:src="option.thumbnail"
                                                     v-bind:style="{width: layer.previewWidth + 'px'}"
                                                     class="img-fluid">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="wizard-section wizard-options wizard-options-bodyCopy"
                                     v-if="layer.selectedOption && layer.selectedOption.bodyCopyOptions && layer.selectedOption.bodyCopyOptions.length">
                                    <div class="wizard-section-header">
                                        <h2>Body Copy</h2>
                                    </div>
                                    <div class="wizard-section-body">
                                        <div class="wizard-option"
                                             v-for="nestedOption in layer.selectedOption.bodyCopyOptions"
                                             :key="nestedOption.id">
                                            <button class="btn-image" v-on:click.prevent="selectNestedOption(nestedOption, layer.selectedOption)"
                                                    v-bind:class="{selected: nestedOption.selected}">
                                                <img v-bind:src="nestedOption.image.url" class="img-fluid"
                                                     v-bind:style="{width: layer.bodyCopyPreviewWidth + 'px'}">
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div v-if="layer.type.handle == 'logo'">
                                <div class="wizard-section wizard-options">
                                    <div class="wizard-section-header">
                                        <h2>Select a Logo</h2>
                                    </div>

                                    <div class="xwizard-section-body">
                                        <div v-for="team in teams" :key="team.id" class="wizard-section-body">
                                            <!--<h5>{{team.title}}</h5>-->
                                            <div class="wizard-options wizard-options-logo wizard-options-image">
                                                <div class="wizard-section-body">
                                                    <div class="wizard-option" v-for="logo in team.logos"
                                                         :key="logo.id">
                                                        <button class="btn-image" v-on:click.prevent="selectLogo(logo, layer)"
                                                                v-bind:class="{selected: logo.selected}">
                                                            <img v-bind:src="logo.url" class="img-fluid">
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div v-if="layer.type.handle == 'userText'">
                                <div class="wizard-section wizard-options">
                                    <div class="wizard-section-header">
                                        <h2>{{layer.label}}</h2>
                                    </div>
                                    <div class="wizard-section-body">
                                        <div class="form-group">
                                            <!--<label :for="'layer' + layer.id">{{layer.label}}</label>-->
                                            <quill-editor class="quill-editor" :id="'layer' + layer.id"
                                                          :options="editorOption"
                                                          v-model="layer.userText"></quill-editor>
                                            <!--<hr>-->
                                            <!--<textarea class="form-control" :id="'debg_layer' + layer.id" v-model="layer.userText"></textarea>-->

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-if="reverseLayers.length" class="wizard-section wizard-options wizard-options-size">
                            <div class="wizard-section-header">
                                <h2>Size Adjustment</h2>
                                <p>You have the option to resize the print ad by a 30% maximum adjustment to the width and/or height. The aspect ratio is automatically updated to reflect any changes.</p>
                            </div>
                            <div class="wizard-section-body">
                                <div class="form-row">
                                    <div class="form-group col-sm-3">
                                        <label for="resize_width">Width</label>
                                        <input v-model="asset.size_adjusted.width"
                                               v-on:blur="syncHeight()"
                                               type="text" class="form-control"
                                               id="resize_width">
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <label for="resize_height">Height</label>
                                        <input v-model="asset.size_adjusted.height"
                                               v-on:blur="syncWidth()"
                                               type="text" class="form-control"
                                               id="resize_height">
                                    </div>
                                    <div class="form-group col-sm-2">
                                        <label for="resize_reset">&nbsp;</label>
                                        <button id="resize_reset"
                                                type="button"
                                                v-on:submit.prevent
                                                v-on:click="resetSize()"
                                                class="btn btn-primary form-control">
                                            <i class="fas fa-undo"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!reverseLayers.length && asset.basePdf" class="text-center">
                            <p>
                                <img v-bind:src="asset.basePdf.preview" alt="">
                            </p>
                            <a v-bind:href="'/download/' + asset.basePdf.downloadToken" class="btn btn-primary btn-block btn-lg">Download</a>
                        </div>
                    </div>
                </div>

            </div>

                <div class="wizard-output " v-if="reverseLayers.length" v-bind:style="{width: asset.preview.width + 'px'}">

                    <div class="wizard-preview-wrapper"  v-bind:style="{height: (asset.preview.height * (zoom/100)) + 'px'}">
                        <div class=" wizard-preview" :class="orientationClass"
                             v-bind:style="previewStyle">

                            <div v-for="layer in reverseLayers" v-bind:key="layer.id"
                                 class="wizard-preview-layer-wrapper"
                                 v-bind:class="layer.type.handle"
                                 v-bind:style="{
                                 zIndex: layer.zIndex,
                                 top: layer.top * 72 * asset.preview.scale + 'px',
                                 left: layer.left * 72 * asset.preview.scale + 'px',
                                 right: layer.right * 72 * asset.preview.scale + 'px',
                                 bottom: layer.bottom * 72 * asset.preview.scale + 'px',
                             }">
                                <div class="wizard-preview-layer">
                                    <transition name="fade">
                                        <div class="img" v-if="layer.selectedOption" draggable="false"
                                             v-bind:style="{
                                         background: 'rgba(blue, 0.5)',
                                         backgroundSize: 'contain',
                                         backgroundRepeat: 'no-repeat',
                                         backgroundPosition: 'top ' + layer.align,
                                         backgroundImage: 'url(' + layer.selectedOption.thumbnail + ')',
                                         width: '100%',
                                         height: '100%'
                                         }"
                                             v-bind:key="layer.selectedOption.id"></div>
                                    </transition>
                                    <div v-if="layer.userText">
                                        <!--<vue-markdown class="result-html full-height"-->
                                        <!--v-bind:style="{-->
                                        <!--transform: 'scale(' + asset.preview.scale + ')'-->
                                        <!--}"-->
                                        <!--:watches="['show','html','breaks','linkify','emoji','typographer','toc']"-->
                                        <!--:source="layer.userText" :show="true" :html="false" :breaks="true" :linkify="true"-->
                                        <!--:emoji="true" :typographer="true" :toc="false" xv-on:rendered="allRight"-->
                                        <!--xv-on:toc-rendered="tocAllRight" toc-id="toc"></vue-markdown>-->
                                    </div>

                                </div>
                                <div v-if="layer.selectedOption">
                                    <div class="wizard-preview-layer"
                                         v-bind:style="{
                                         top: layer.bodyCopyTop * 72 * asset.preview.scale + 'px',
                                         left: layer.bodyCopyLeft * 72 * asset.preview.scale + 'px',
                                         right: layer.bodyCopyRight * 72 * asset.preview.scale + 'px',
                                         width: asset.preview.width - (layer.bodyCopyRight * 72 * asset.preview.scale) - layer.bodyCopyLeft * 72 * asset.preview.scale + 'px',
                                         marginLeft: -layer.left * 72 * asset.preview.scale + 'px',
                                         marginTop: -layer.top * 72 * asset.preview.scale + 'px'
                                         }">
                                        <transition name="fade">
                                            <img v-if="layer.selectedOption.selectedOption" draggable="false"
                                                 v-bind:src="layer.selectedOption.selectedOption.image.url"
                                                 v-bind:key="layer.selectedOption.selectedOption.id">
                                        </transition>
                                    </div>
                                </div>
                                <div v-if="layer.selectedLogo">
                                    <div class="wizard-preview-layer wizard-preview-layer-draggable"
                                         v-bind:class="{ active: layer.active }"
                                         v-bind:style="{
                                        // top: layer.top * 72 * asset.preview.scale + 'px',
                                        // left: layer.left * 72 * asset.preview.scale + 'px',
                                        // right: layer.right * 72 * asset.preview.scale + 'px',
                                        height: layer.height * 72 * asset.preview.scale + 'px',
                                        }">
                                        <vue-draggable-resizable :key="layer.selectedLogo.id" :parent="true"
                                                                 :x="layer.draggable.left"
                                                                 :y="layer.draggable.top"
                                                                 :w="layer.draggable.width"
                                                                 :h="layer.draggable.height"
                                                                 :active="layer.active"
                                                                 :handles="['tl','br']"
                                                                 :minh="10"
                                                                 :grid="[1,1]"
                                                                 :lockAspectRatio="true"
                                                                 @activated="onActivate(layer)"
                                                                 @deactivated="onDeactivate(layer)"
                                                                 @resizing="(left, top, width, height) => onResize(layer, left, top, width, height)"
                                                                 @dragging="(left, top) => onDrag(layer, left, top)">
                                            <div style="width: 100%; height: 100%; background-repeat: no-repeat; background-size: contain;"
                                                 v-bind:style="{
                                            backgroundImage: 'url(' + layer.selectedLogo.url + ')',
                                            backgroundPosition: layer.align,
                                        }">
                                            </div>
                                        </vue-draggable-resizable>
                                    </div>
                                </div>
                                <div v-if="layer.userText">

                                    <div class="wizard-preview-layer wizard-preview-layer-draggable"
                                         v-bind:class="'wizard-preview-layer-' + layer.id"
                                         v-bind:style="{
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        height: '100%',
                                        fontSize: layer.fontSize * asset.preview.scale + 'px',
                                        color: layer.color.hex,
                                        lineHeight: layer.lineHeight,
                                        fontFamily: 'Arial',
                                        textAlign: layer.align
                                        }">
                                        <vue-draggable-resizable :parent="true" :x="layer.draggable.left"
                                                                 :y="layer.draggable.top" :h="layer.draggable.height"
                                                                 :w="layer.draggable.width"
                                                                 @activated="onActivate(layer)"
                                                                 @deactivated="onDeactivate(layer)"
                                                                 @resizing="(left, top, width, height) => onResize(layer, left, top, width, height)"
                                                                 @dragging="(left, top) => onDrag(layer, left, top)">

                                            <div class="userText-preview" v-html="layer.userText"></div>

                                            <!-- <vue-markdown class="result-html full-height"
                                                          v-bind:style="{
                                                fontSize: 16 * asset.preview.scale + 'px',
                                                }"
                                                          :watches="['show','html','breaks','linkify','emoji','typographer','toc']"
                                                          :source="layer.userText" :show="true" :html="false" :breaks="true"
                                                          :linkify="true"
                                                          :emoji="true" :typographer="true" :toc="false"
                                                          xv-on:rendered="allRight"
                                                          xv-on:toc-rendered="tocAllRight" toc-id="toc"></vue-markdown> -->
                                        </vue-draggable-resizable>
                                    </div>
                                </div>
                            </div>


                            <div class="grid-lines" v-if="showGridLines">
                                <div class="grid-lines-h" v-for="index_h in asset.gridBounds.height" :key="index_h">
                                    <div class="grid-line grid-line-half "
                                         v-bind:style="{ top: (index_h - 0.5) * 72 * asset.preview.scale + 'px' }"></div>
                                    <div class="grid-line grid-line-whole grid-line-1"
                                         v-bind:style="{ top: index_h * 72 * asset.preview.scale + 'px' }"></div>
                                </div>
                                <div>
                                    <div class="grid-lines-v" v-for="index_v in asset.gridBounds.width" :key="index_v">
                                        <div class="grid-line grid-line-half "
                                             v-bind:style="{ left: (index_v - 0.5) * 72 * asset.preview.scale + 'px' }"></div>
                                        <div class="grid-line grid-line-whole grid-line-1"
                                             v-bind:style="{ left: index_v * 72 * asset.preview.scale + 'px' }"></div>
                                    </div>
                                </div>
                            </div>



                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 2 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 3 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 4 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 5 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 6 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 7 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 8 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 9 * 72 * asset.preview.scale + 'px' }"></div>-->
                            <!--<div class="grid-line grid-line-1"-->
                            <!--v-bind:style="{ top: 10 * 72 * asset.preview.scale + 'px' }"></div>-->


                        </div>

                    </div>
                    <div class="form" v-if="false">
                        <div class="form-group">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" v-model="showGridLines" value="" id="defaultCheck1">
                                <label class="form-check-label" for="defaultCheck1">
                                    Show Grid
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="wizard-actions" v-if="false" v-bind:style="{width: asset.preview.width + 'px'}">
                        <p>
                            <button type="button" class="btn btn-success btn-lg btn-block"
                                    v-bind:disabled="generating"
                                    v-on:click="generatePdf()">
                                <span v-if="!generating">Generate PDF</span>
                                <span v-if="generating">{{generating}}...</span>
                            </button>
                        </p>

                        <div class="alert alert-success" v-if="pdfUrl">
                            <p><i class="fas fa-check"></i> Your PDF is ready:</p>
                            <div class="btn-toolbar">
                                <a v-if="pdfUrl" v-bind:href="pdfUrl" target="_blank" rel="noopener"
                                   class="btn btn-secondary"><i class="fas fa-eye"></i> View</a>
                                <button v-if="lastPdf" type="button" class="btn btn-secondary" data-toggle="modal"
                                        data-target="#exampleModal">
                                    <i class="fas fa-share"></i> Share
                                </button>
                            </div>
                        </div>

                        <div v-if="debug">
                            <div v-if="assetPreview">
                                <img v-bind:src="assetPreview" alt="">
                            </div>
                            <div v-if="pdfUrl">
                                <iframe v-bind:src="pdfUrl" :width="asset.preview.width"
                                        :height="asset.preview.height + 70"
                                        frameborder="0"></iframe>
                            </div>
                        </div>
                    </div>




                </div>


        </div>


        <!--<pre>{{asset}}</pre>-->
        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Email PDF</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-status" v-if="generating">
                            <div class="modal-status-message">
                                {{generating}}
                            </div>
                        </div>


                        <form v-bind:class="{submitting: generating}">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input v-model="email.email" type="email" class="form-control" id="exampleInputEmail1"
                                       aria-describedby="emailHelp" placeholder="Enter email">
                                <small id="emailHelp" class="form-text text-muted">Separate multiple addresses with commas.
                                </small>
                            </div>
                            <div class="form-group">
                                <label for="emailPdfMessage">Message</label>
                                <textarea v-model="email.message" class="form-control" name="emailPdfMessage" id="emailPdfMessage" rows="4"></textarea>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-success"
                                v-if="!generating"
                                v-on:click="emailAsset()">Send
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>
