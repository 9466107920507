<script>
    import axios from 'axios';
    import LineChart from './LineChart.js'

    export default {
        name: 'MatomoVisitsSummary',
        props: ['params'],
        components: {
            LineChart
        },
        watch: {
            $route(to, from) {
                this.idCustomReport = to.params.reportId;
                console.log('to', to);
                this.getReport();
            }
        },
        data () {
            return {
                showModal: false,
                msg: 'Hello World',
                report: {},
                reports: {
                    list: [],
                    loading: false,
                },
                status: {
                    loading: false,
                    errors: []
                },
                matomoParams: {},
                idCustomReport: null,
                flat: 0,
                query: {},
                foo: {},
                showProfileDiv: false,
                datacollection: null,
                method: 'API.getProcessedReport',
                defaultParams: {
                    date: 'today',
                    period: 'month'
                },
                chartOptions: {
                    height: 300,
                    responsive: true,
                    maintainAspectRatio: false
                },
                height: 100
            }
        },
        filters: {

        },

        methods: {
            onUpdateParams: function(val){

                console.log('custom report params updated');
                console.log(val);
                this.getReport(val);
                this.matomoParams = val;
            },
            changeReport: function() {
                console.log('change report');
                console.log('this.idCustomReport', this.idCustomReport);
                // this.$router.push({path: '/report', params: {reportId: this.idCustomReport}})
                this.$router.push({ path: `/report/${this.idCustomReport}` }) // -> /user/123
            },

            fillData () {
                this.datacollection = {
                    labels: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: 'blue',
                            fill: false,
                            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
                        }, {
                            label: 'Data Two',
                            backgroundColor: 'red',
                            data: [this.getRandomInt(), this.getRandomInt(), this.getRandomInt(), this.getRandomInt()]
                        }
                    ]
                }
            },
            getRandomInt () {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5
            },
            flatten: function() {
                if (this.flat === 1) {
                    this.flat = 0;
                } else {
                    this.flat = 1;
                }
                this.getReport(this.matomoParams);

            },
            getReportList: function() {

                console.log('⭐ getting report list');

                this.reports.loading = true;

                let params = {
                    filter_limit: -1,
                    format: 'JSON',
                    method: 'CustomReports.getConfiguredReports',
                    module: 'API',
                };
                console.log('params', params);

                return axios.get('/actions/matomo-module/default', {
                    params
                })
                    .then(response => {
                        console.log('report list response', response);

                        let reports = [];
                        if (response.data.data) {
                            response.data.data.forEach((item) => {
                                console.log('item', item);
                                if (item.idsite !== 0 && item.report_type === 'table') {
                                    reports.push({
                                        id: item.idcustomreport,
                                        name: item.name,
                                    })
                                }
                            })
                        }
                        this.reports.loading = false;
                        this.reports.list = reports;
                    })
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response)
                    })
            },
            getReport: function(params) {
                console.log("getReport");
                console.log('this.$route', this.$route);
                console.log(params);
                if (!params) {
                    params = this.matomoParams;
                }

                this.status.loading = true;
                this.report = {loading: true};

                console.log('this.method', this.method);

                let oldParams = {
                    // method: 'CustomReports.getCustomReport',
                    // date: 'today',
                    // period: 'year',
                    // idCustomReport: 3,
                    // expanded: 1,

                    'apiAction': 'getCustomReport',
                    'apiModule': 'CustomReports',
                    'date': 'today',
                    'expanded': '1',
                    'filter_limit': '-1',
                    'format': 'JSON',
                    'idCustomReport': '7',
                    'idSite': '2',
                    'method': 'API.getProcessedReport',
                    'module': 'API',
                    'period': 'month',
                    'reportUniqueId': 'CustomReports_getCustomReport_idCustomReport--3',
                    'segment': '',
                    'token_auth': '3065a4593d6670aef6fcc818b8ca6df0'
                }

                // params = {...params,
                //     method: this.method,
                //     idCustomReport: 7,
                //     apiModule: 'CustomReports',
                //     apiAction: 'getCustomReport'
                // };

                params = {...params,
                    apiAction: 'getCustomReport',
                    apiModule: 'CustomReports',
                    expanded: 1,
                    filter_limit: -1,
                    format: 'JSON',
                    idCustomReport: this.$route.params.reportId,
                    method: 'API.getProcessedReport',
                    module: 'API',
                    flat: this.flat,
                };
                console.log('params', params);

                return axios.get('/actions/matomo-module/default', {
                    params
                })
                    .then(response => {
                        console.log('response', response);
                        this.status.loading = false;
                        if (response.data.error) {
                            this.status.errors.push(response.data.error);
                        } else {
                            this.status.errors = [];

                            if (response.data.data.reportMetadata) {
                                let i = 0;
                                let merged = response.data.data.reportData.map(item => {
                                    console.log('response.data.data.reportMetadata[i]', response.data.data.reportMetadata[i]);
                                    i++;
                                    return {...item, ...response.data.data.reportMetadata[i-1], subTable: null, subTableLoading: false, key: i}
                                    // return {hey: 'there'}
                                });
                                response.data.data.reportData = merged;
                            }
                            this.report = response.data.data;
                        }
                    })
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response)
                    })
            },
            getReportSubtable: function(item) {
                console.log('⭐ getSubReport');
                console.log('item', item);
                if (item.subTable) {
                    item.subTable = null;
                    return;
                }
                item.subTableLoading = true;

                let params = {...this.matomoParams,
                    apiAction: 'getCustomReport',
                    apiModule: 'CustomReports',
                    expanded: 1,
                    filter_limit: -1,
                    format: 'JSON',
                    idCustomReport: this.$route.params.reportId,
                    method: 'API.getProcessedReport',
                    module: 'API',
                    idSubtable: item.idsubdatatable_in_db
                };
                console.log('params', params);

                return axios.get('/actions/matomo-module/default', {
                    params
                })
                    .then(response => {
                        console.log('response', response);
                        this.status.loading = false;


                        if (response.data.data.reportMetadata) {
                            let i = 0;
                            let mergedMeta = response.data.data.reportData.map(item => {
                                console.log('response.data.data.reportMetadata[i]', response.data.data.reportMetadata[i]);
                                i++;
                                return {...item, ...response.data.data.reportMetadata[i-1], subTable: null, subTableLoading: false, key: item.key + "_" + i}
                                // return {hey: 'there'}
                            });
                            response.data.data.reportData = mergedMeta;
                        }
                        item.subTableLoading = false;

                        if (response.data.error) {
                            this.status.errors.push(response.data.error);
                        } else {
                            this.status.errors = [];

                            console.log('stopped loading subtable');
                            item.subTableLoading = false;
                            item.subTable = response.data.data;
                            // this.subReport = response.data.data;
                            // this.showModal = true;
                        }
                    })
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response)
                    })
            },
            showProfile: function(item) {
                // this.showProfileDiv = true;
                console.log('item', item);
                item.showProfileDiv = !item.showProfileDiv;
            }
        },
        mounted: function(params) {
            this.getReportList();
            // this.fillData();
            // console.log('this.datacollection', this.datacollection);
            // this.getReport(this.params).then(response=>{
            //     console.log('response.data', response.data.visitorId);
            //     this.report = response.data.data;
            // });
        },
        computed: {
            myStyles () {
                return {
                    height: `${this.height}px`,
                    position: 'relative'
                }
            }
        }
    }
</script>

<template>

    <div>

        <MatomoParams v-on:update-params="onUpdateParams($event)"></MatomoParams>


         <div class="card-stat mb-4" v-if="idCustomReport">

            <div class="card-body" >



<!--                <pre>{{report}}</pre>-->

                <div v-if="report.loading">
                    <p>Loading Report...</p>
                    <hr class="mt-4">

                </div>
                <div v-if="report && report.metadata">
                    <h2>{{report.metadata.name}}</h2>
                    <p>{{report.prettyDate}}</p>
                    <img v-if="false" :src="'https://platform-b.matomo.cloud/' + report.metadata.imageGraphUrl" alt="">
                    <img v-if="false" :src="'https://platform-b.matomo.cloud/' + report.metadata.imageGraphEvolutionUrl" alt="">


                    <div v-if="report.reportData.length">
                        <table class="table table-sm" >

                            <thead class="thead-dark">
                            <tr>
                                <th>{{report.metadata.dimension}}</th>
                                <th v-for="(value, name) in report.metadata.metrics">
                                    {{value}}
                                </th>
                            </tr>

                            </thead>
                            <tbody v-for="row in report.reportData" v-bind:key="row.key">
                            <tr v-if="false">
                                <td colspan="2">
                                  <h1>row:</h1>
                                    <pre>{{row}}</pre>
                                </td>
                            </tr>
                            <tr>
                                <td style="white-space: nowrap">
                                    <button v-show="row.idsubdatatable_in_db && !row.subTable && !row.subTableLoading"
                                            v-on:click="getReportSubtable(row)"
                                            class="btn btn-default btn-xs">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                    <button v-show="row.subTable"
                                            v-on:click="getReportSubtable(row)"
                                            class="btn btn-default btn-xs">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <button v-show="row.subTableLoading"
                                            v-on:click="getReportSubtable(row)"
                                            class="btn btn-default btn-xs">
                                        <i class="fas fa-spinner fa-spin"></i>
                                    </button>

                                    {{row.label}}
                                </td>
                                <td v-for="(value, name) in report.metadata.metrics" :key="row.key + name" v-bind:class="row.key + name">
                                    {{row[name]}}
                                </td>
                            </tr>
                            <!--<tr>
                                <td colspan="4"><pre>{{row.subTable}}</pre></td>
                            </tr>-->
                            <template v-if="row.subTable">
                                <tr v-if="false">
                                    <td colspan="2">
                                        <h1>subtable:</h1>
                                        <pre>{{row.subTable}}</pre>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="pl-4">{{row.subTable.metadata.dimension}}</th>
                                    <th v-for="(value, name) in row.subTable.metadata.metrics">
                                        {{value}}
                                    </th>

                                </tr>

                                <template v-for="subrow in row.subTable.reportData">
                                    <tr>
                                        <td class="pl-4">
                                            <button v-show="subrow.idsubdatatable_in_db && !subrow.subTable && !subrow.subTableLoading"
                                                    v-on:click="getReportSubtable(subrow)"
                                                    class="btn btn-default btn-xs">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                            <button v-show="subrow.subTable"
                                                    v-on:click="getReportSubtable(subrow)"
                                                    class="btn btn-default btn-xs">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                            <button v-show="subrow.subTableLoading"
                                                    v-on:click="getReportSubtable(subrow)"
                                                    class="btn btn-default btn-xs">
                                                <i class="fas fa-spinner fa-spin"></i>
                                            </button>
                                            {{subrow.label}}
                                        </td>
                                        <td v-for="(value, name) in row.subTable.metadata.metrics">
                                            {{subrow[name]}}
                                        </td>
                                    </tr>
                                    <template v-if="subrow.subTable">
                                      <tr>
                                        <th class="pl-4">&nbsp;</th>
                                        <th v-for="(value, name) in row.subTable.metadata.metrics">
                                          {{value}}
                                        </th>
                                      </tr>

                                        <tr v-if="false">
                                            <th class="pl-5">{{subrow.subTable.metadata.dimension}}</th>
                                            <th v-for="(value, name) in subrow.subTable.metadata.metrics">
                                                {{value}}
                                            </th>
                                        </tr>

                                        <template v-for="subsubrow in subrow.subTable.reportData">
                                            <tr>
                                                <td class="pl-5" v-on:click="getReportSubtable(subrow)">
                                                    {{subsubrow.label}}
                                                </td>
                                                <td v-for="(value, name) in subrow.subTable.metadata.metrics">
                                                    {{subsubrow[name]}}
                                                </td>
                                            </tr>
                                        </template>

                                    </template>
                                </template>

                            </template>

                            </tbody>

                        </table>
                        <button class="btn btn-primary" v-on:click="flatten()">
                            <span v-if="this.flat">Make Hierarchical</span>
                            <span v-else>Make Flat</span>
                        </button>
                    </div>
                    <div class="alert alert-warning" v-else>
                        There is no data for this report. If the report was created or updated only recently, it may take a few hours for the data to become available when you are viewing today's reports. The report data for dates before this report was created won't become available.
                    </div>

                </div>

            </div>
        </div>

        <div class="card card-stat">
            <div class="card-body">
                <h2 class="">Available Reports</h2>
                <div v-if="reports.loading">
                    <p>Loading...</p>
                </div>
                <div v-if="reports.list.length">

                    <div class="form-group" v-if="false">
                        <select v-model="idCustomReport" class="form-control" @change="changeReport()">
                            <option v-for="report in reports" :value="report.id">{{report.name}}</option>
                        </select>
                    </div>

                    <ul v-if="false">
                        <li v-for="report in reports.list">
                            <router-link  :to="{ path: `/report/${report.id}` }" class="">
                                {{ report.name }}
                            </router-link>
                        </li>

                    </ul>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="list-group">
                                <router-link v-for="report in reports.list" :to="{ path: `/report/${report.id}` }" class="list-group-item list-group-item-action">
                                    {{ report.name }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .custom-report-item-title {
        font-size: 1rem;
    }
    .custom-report-stat {
        margin-right: 2rem;
    }

    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
</style>
